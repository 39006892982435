import React, { useCallback, useEffect, useState } from 'react';
import logo from './logo.svg';
import Button from 'react-bootstrap/Button';
import { InputGroup, FormControl, ButtonGroup, Form } from "react-bootstrap";
import { useImmer } from "use-immer";
import './App.css';
import * as THREE from 'three';
import { TrackballControls } from "./TrackballControls";
(window as any).THREE = THREE;

function ChainBuilder() {
  const [currentChain, setChain] = useImmer<Chain>([
    { type: "run", distance: 300 },
    { type: "bend", degrees: 90, direction: "left" },
    { type: "run", distance: 150 }
  ]);
  const canAddRun = currentChain.length == 0 || (currentChain[currentChain.length-1].type != "run");
  const canAddBend = currentChain.length == 0 || (currentChain[currentChain.length-1].type != "bend");
  return <>
    <div className="left">
      <div className="header">
        <div className="headerHeader">
          Corsair Hardline Calculator
        </div>
        <div className="headerSubtext">
          A handy calculator for calculating the measurements you need to make a perfect cooling loop.
        </div>
      </div>
      <ButtonGroup className="addActions">
        <Button disabled={!canAddBend} onClick={() => {
          setChain(([] as any).concat(currentChain).concat([
            { type: "bend", degrees: 90, direction: "left" }
          ]) as any)
        }}>Add Bend</Button>
        <Button disabled={!canAddRun} onClick={() => {
          setChain(([] as any).concat(currentChain).concat([
            { type: "run", distance: 100 }
          ]) as any)
        }}>Add Run</Button>
      </ButtonGroup>
      {currentChain.map((e, i) => {
        switch (e.type) {
          case "run":
            return <RunChain distance={e.distance} onUpdate={(distance) => {
              setChain(newChain => {
                (newChain[i] as ChainRun).distance = distance;
              });
            }} onDelete={()=>{
              setChain(newChain => {
                newChain.splice(i, 1)
              })
            }} />
          case "bend":
            return <BendChain degrees={e.degrees} direction={e.direction} onDegreesChange={degrees => {
              setChain(newChain => {
                (newChain[i] as ChainBend).degrees = degrees;
              })
            }} onDirectionChange={direction => {
              setChain(newChain => {
                (newChain[i] as ChainBend).direction = direction as any;
              })
            }} onDelete={()=>{
              setChain(newChain => {
                newChain.splice(i, 1)
              })
            }} />
        }
        return <></>
      })}
      <Instructions chain={currentChain} />
    </div>
    <div className="right">
      <Rendering chain={currentChain} />
    </div>
  </>
}

function RunChain(props: { distance: number, onUpdate: (distance: number) => void, onDelete: ()=>void }) {
  return <>
    <InputGroup>
      <InputGroup.Text>Run</InputGroup.Text>
      <FormControl placeholder="mm" type="number" value={props.distance} onChange={ev => {
        props.onUpdate(Number(ev.target.value))
      }} />
      <InputGroup.Text>mm</InputGroup.Text>
      <Button variant="danger"  onClick={props.onDelete}>-</Button>
    </InputGroup>
  </>
}
function BendChain(props: { degrees: number, direction: string, onDegreesChange: (degrees: number) => void, onDirectionChange: (direction: string) => void, onDelete: ()=>void }) {
  return <>
    <InputGroup>
      <InputGroup.Text>Bend</InputGroup.Text>
      <FormControl placeholder="degrees" value={props.degrees} onChange={ev => {
        props.onDegreesChange(Number(ev.target.value))
      }} />
      <InputGroup.Text>degrees going</InputGroup.Text>
      <Form.Select value={props.direction} onChange={ev => {
        props.onDirectionChange(ev.target.value);
      }}>
        <option value="left">Left</option>
        <option value="right">Right</option>
        <option value="up">Up</option>
        <option value="down">Down</option>
      </Form.Select>
      <Button variant="danger" onClick={props.onDelete}>-</Button>
    </InputGroup>
  </>
}

interface ChainRun {
  type: "run";
  distance: number;
}

interface ChainBend {
  type: "bend";
  degrees: number;
  direction: "left" | "right" | "up" | "down";
}

type ChainAction = ChainRun | ChainBend;
type Chain = ChainAction[];

function Rendering(props: { chain: Chain }) {
  const [scene, setScene] = useState<THREE.Scene>(new THREE.Scene());
  useEffect(()=>{
    scene.background = new THREE.Color(0xf0f0f0);
  }, [])
  const [camera, setCamera] = useState<THREE.PerspectiveCamera>(new THREE.PerspectiveCamera(50, 1, 0.01, 10000));
  useEffect(()=>{
    scene.add(camera);
    camera.position.set(304*3, 304*3, 304*3); // all components equal
    camera.lookAt(scene.position); // or the origin
    return ()=>{
      scene.remove(camera);
    }
  }, [camera, scene]);
  const [light, setLight] = useState<THREE.Light>(new THREE.DirectionalLight(0xffffff));
  useEffect(()=>{
    camera.add(light);
    return ()=>{
      camera.remove(light);
    }
  }, [camera, light]);
  const [renderer, setRenderer] = useState<THREE.Renderer>(new THREE.WebGLRenderer({antialias:true}));
  useEffect(()=>{
    let stop = false;
    const controls = new TrackballControls(camera, renderer.domElement);
    function animate() {
      if(stop) {
        return;
      }
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    }
    animate();
    return ()=>{
      stop = true;
    }
  }, [camera, renderer, scene])
  const ref = useCallback((node: any) => {
    if (node == null) {
      return
    }
    renderer.setSize(node.offsetWidth, node.offsetHeight);
    if(renderer.domElement.parentElement) {
      renderer.domElement.parentElement.removeChild(renderer.domElement);
    }
    camera.aspect=node.offsetWidth / node.offsetHeight;
    camera.updateProjectionMatrix();
    node.appendChild(renderer.domElement);
    
    const resizeObserver = new ResizeObserver(() => {
      if (node == null) {
        resizeObserver.disconnect();
        return;
      }
      camera.aspect = node.offsetWidth / node.offsetHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(node.offsetWidth, node.offsetHeight);
      
    });
    resizeObserver.observe(node);
  }, []);

  useEffect(() => {
    if (props.chain.length == 0) {
      return;
    }
    const pointsList: THREE.Vector3[] = [
      new THREE.Vector3(0, 0, 0)
    ];
    let lastPoint: () => THREE.Vector3 = () => pointsList[pointsList.length - 1];
    let quat = new THREE.Quaternion();
    let euler = new THREE.Euler();
    quat.setFromAxisAngle(new THREE.Vector3(0, 0, 0), Math.PI / 2);
    for (let i=0; i<props.chain.length; i++) {
      let action = props.chain[i];
      switch (action.type) {
        case "run":
          const startPoint = lastPoint();
          // const endPoint = new THREE.Vector3().add(startPoint).add(new THREE.Vector3(action.distance, 0, 0));
          // let delta = new THREE.Vector3().subVectors(endPoint, startPoint);
          // delta = delta.applyQuaternion(quat);
          //let additive = new THREE.Vector3(action.distance, 0, 0).applyEuler(euler);
          let distance = action.distance;
          if(i < props.chain.length-1) {
            const nextAction = props.chain[i+1];
            if(nextAction.type == "bend") {
              distance-=30;
            }
          }
          let additive = new THREE.Vector3(distance, 0, 0).applyQuaternion(quat);
          let newPoint = new THREE.Vector3().addVectors(startPoint, additive);
          pointsList.push(newPoint);
          break;
        case "bend":
          for (let i = 0; i < action.degrees; i++) {
            let tempEuler = new THREE.Euler(0,0,0);
            switch (action.direction) {
              case "left":
                // euler.r
                tempEuler = new THREE.Euler(0, -1 * (Math.PI / 180), 0);
                quat.multiply(new THREE.Quaternion().setFromEuler(tempEuler));
                euler.y -= 1 * (Math.PI / 180);
                break;
              case "right":
                tempEuler = new THREE.Euler(0, 1 * (Math.PI / 180), 0);
                quat.multiply(new THREE.Quaternion().setFromEuler(tempEuler));
                euler.y += 1 * (Math.PI / 180)
                break;
              case "up":
                tempEuler = new THREE.Euler(0, 0, 1 * (Math.PI / 180));
                quat.multiply(new THREE.Quaternion().setFromEuler(tempEuler));
                euler.z += 1 * (Math.PI / 180)
                break;
              case "down":
                tempEuler = new THREE.Euler(0, 0, -1 * (Math.PI / 180));
                quat.multiply(new THREE.Quaternion().setFromEuler(tempEuler));
                euler.z -= 1 * (Math.PI / 180)
                break;
            }
            const startPoint = lastPoint();
            // let additive = new THREE.Vector3(1 / 360 * 2 * Math.PI * 30, 0, 0).applyEuler(euler);
            let additive = new THREE.Vector3(1 / 360 * 2 * Math.PI * 30, 0, 0).applyQuaternion(quat);
            let newPoint = new THREE.Vector3().addVectors(startPoint, additive);
            pointsList.push(newPoint);
          }
          break
      }
    }
    if (pointsList.length < 2) {
      return
    }
    const reversed = ([] as any).concat(pointsList).reverse();
    const curve = new THREE.CatmullRomCurve3(pointsList.concat(reversed));
    const extrudeSettings1 = {
      steps: 100,
      bevelEnabled: false,
      extrudePath: curve
    };
    const tubeGeometry = new THREE.TubeGeometry(curve, 300, 6, 300, true);
    const material = new THREE.MeshLambertMaterial({ color: 0xff00ff });
    const mesh = new THREE.Mesh(tubeGeometry, material);
    const obj = new THREE.Object3D();
    obj.add(mesh);
    // Create the final object to add to the scene
    // const curveObject = new THREE.Line(geometry, material);
    scene.add(obj);
    return () => {
      scene.remove(obj);
    }
  }, [props.chain])
  return <><div ref={ref} className="rendering">
  </div></>
}

function App() {
  return (
    <div className="App">
      <ChainBuilder />


    </div>
  );
}

export default App;


function drawCylinder(vstart: THREE.Vector3, vend: THREE.Vector3) {
  var HALF_PI = Math.PI * .5;
  var distance = vstart.distanceTo(vend);
  var position = vend.clone().add(vstart).divideScalar(2);

  var material = new THREE.MeshLambertMaterial({ color: 0x0000ff });
  var cylinder = new THREE.CylinderGeometry(10, 10, distance, 10, 10, false);

  var orientation = new THREE.Matrix4();//a new orientation matrix to offset pivot
  var offsetRotation = new THREE.Matrix4();//a matrix to fix pivot rotation
  var offsetPosition = new THREE.Matrix4();//a matrix to fix pivot position
  orientation.lookAt(vstart, vend, new THREE.Vector3(0, 1, 0));//look at destination
  offsetRotation.makeRotationX(HALF_PI);//rotate 90 degs on X
  orientation.multiply(offsetRotation);//combine orientation with rotation transformations
  cylinder.applyMatrix4(orientation)

  var mesh = new THREE.Mesh(cylinder, material);
  mesh.position.set(position.x, position.y, position.z);
  return mesh;
}


function Instructions(props: {chain: Chain}) {
  if(props.chain.length == 0) {
    return <>Add some bends!</>
  }
  let instructions: string[] = [];
  for(let i=0; i<props.chain.length-1; i++) {
    const thisChain = props.chain[i];
    const nextChain = props.chain[i+1];
    if(thisChain.type == "run" && nextChain.type == "bend") {
      const angle = nextChain.degrees;
      const radius = 30;
      const angleValue = angle/360;
      const angleLength = angleValue*2*Math.PI*radius;
      const runLength = thisChain.distance;
      const bendStart = runLength - radius;
      const bendEnd = bendStart + angleLength;
      instructions.push(`Measure ${Math.round(bendStart)}mm, mark as base of bend.`);
      instructions.push(`Measure ${Math.round(angleLength)}mm more, mark as end of bend. (Total ${Math.round(bendEnd)}mm)`);
    }
    if(thisChain.type == "run" && nextChain.type == "run") {
      instructions.push(`Measure ${Math.round(thisChain.distance)}mm (Psst, combine the two run rules)`);
    }
  }
  const lastChain = props.chain[props.chain.length-1]
  if(lastChain.type == "run") {
    instructions.push(`Measure ${lastChain.distance}mm`);
  }
  return <div className="instructions">
    <div className="instructionsTitle">Instructions</div>
  <ol>
    {instructions.map(i=><li>{i}</li>)}
    
    </ol></div>
}